import React from "react"
import {Link, navigate } from 'gatsby'
import {
	Container,
	Button,
	Form,
	Message,
	Header,
} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import SignInOTP from '../components/Forms/signInOTPChangePassword'
import * as styles from './styles/pages.module.css'
import {confirmationColor, backColor} from '../globalVar'

const Negosyo = require('../../lib/negosyo')


class ResetPassword extends React.Component {
	state={
		contact: "",
		stage:1,
		error:'',
		loading:false,
		errorMessage: {
			contact: undefined
		}
	}



	validateInput = () => {
		var contactval = ""
		if(this.state.contact !== undefined ) {
			this.setState({
				contact:this.state.contact.trim()
			})
			contactval = this.state.contact.trim()
		}

		const pattern = /^(0|\+63)(8|9)([0-9]{9})$/g
		const result = pattern.test(contactval)

		if(contactval ===''  || this.state.contact === undefined ) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					contact : "this must not be blank"
				}
			}))
			return false
		} else if(!result) {

			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					contact : "must be a valid mobile number"
				}
			}))
			return false
		}else {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					contact : undefined
				}
			}))
			return true
		}
	}

	componentDidMount() {
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data===true) {
				//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
				navigate(
					`/my-account`,
					{
						replace:true,
						state:{
							customKey:this.props.location.key
						}
					}
				)
			}
		})//doesn't need error since isLoggedIn won't reject promise but will only resolve with true if logged in or false
	}

	handleInputChange = (e, {name, value}) => {
		if (name === "contact" || name === "email") {
			value = value.replace(/\s/g,"");
		}

		this.setState({ [name]: value })
		if(value==='') {
			const message = "this must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}

	submitContact = () => {
		this.setState({
			loading:true
		})
		const {
			contact,
		} = this.state
		if(this.validateInput()) {
			Negosyo.getUser(contact)
			.then((data)=>{
				if(data.status==="Active") {
					if(data.logintype==="Password") {
						this.setState({stage:1, error:"", loading:false})
					}else {
						this.setState({error:"There is no account with that contact detail", loading:false})
					}

				}else if(data.status==="Inactive"){
					this.setState({
						loading:false,
						error:"There is no account with that contact detail"
					})
				}else if(data.status==="None"){
					this.setState({
						error:"There is no account with that contact detail",
						loading:false
					})
				}else { //status === error and whatever else
					this.setState({error:"Error occurred, please try again", loading:false})
				}
			}).catch((err)=>{
				this.setState({error:"Error occurred, please try again", loading:false})
			})
		}else {
			this.setState({
				loading:false
			})
		}
	}

	render() {
		const {
			location
		} = this.props
		const {
			handleInputChange,
			submitContact
		} = this
		const {
			contact,
			stage,
			loading,
			error,
			errorMessage
		} = this.state
		var tmpcontact = "";
		if (typeof this.props.location.state !== "undefined") {
			if (typeof this.props.location.state.contact !== "undefined") {
				tmpcontact = this.props.location.state.contact;
			}
		}

		return (
			<Layout location={location}>
				<Seo title={"Reset Password"} meta={[{name: `robots`, content: `noindex`}]} />
				<Container className={styles.container}>
					<div className={styles.centerContent}>
						{tmpcontact.length > 0?
							<SignInOTP
								header={"Use Password Reset Authorization Code"}
								contact={tmpcontact}
								resendCode={false}
								location={location}
								backFunc={()=>{
									navigate('/sign-in/')
								}}
							/>
						:
							<SignInOTP
								header={"Use Password Reset Authorization Code"}

								resendCode={false}
								location={location}
								backFunc={()=>{
									navigate('/sign-in/')
								}}
							/>

						}
					</div>
				</Container>
			</Layout>
		)
	}
}

export default ResetPassword
